import { ConferenceHeader, EventCost, EventProgram, Faq, Footer, Section, Speakers } from 'components';
import { Header } from 'components/landing/header/Header'
import React, { useEffect, useRef, useState } from 'react'
import arcBottom from "assets/img/arc.png";
import arcFooter from 'assets/img/arcFooter.png';

export const Main = () => {

  const refProgram = useRef<HTMLDivElement | null>(null);

  const refSpeakers = useRef<HTMLDivElement | null>(null);

  const refCost = useRef<HTMLDivElement | null>(null);

  const refFAQ = useRef<HTMLDivElement | null>(null);

  const refContacts = useRef<HTMLDivElement | null>(null);

  const [arrRefs, setArrRefs] = useState<any[]>([])

  useEffect(() => {
    setArrRefs([
      {
        id: 0,
        ref: refProgram,
        name: 'Программа'
      },
      {
        id: 1,
        ref: refSpeakers,
        name: 'Спикеры'
      },
      {
        id: 2,
        ref: refCost,
        name: 'Тариф'
      },
      {
        id: 3,
        ref: refFAQ,
        name: 'FAQ'
      },
      {
        id: 4,
        ref: refContacts,
        name: 'Контакты',
      }
    ])
  }, [])


  return (
    <div className='bg-black2 text-white flex flex-col'>
    <Header refs={arrRefs} />
    <main>
      <Section styled={"mt-0 pt-8 s:pt-[144px] gradientBgConf"}>
          <ConferenceHeader refByTicket={refCost} />
      </Section>
      <Section title="Программа" styled='bgProgram' refSection={refProgram} id="program">
        <EventProgram />
      </Section>
      <Section title="Спикеры" refSection={refSpeakers} id='speakers'>
        <Speakers />
      </Section>
      <img src={arcBottom} alt="" className="w-full relative top-[2px]" />
      <Section styled="bg-white mt-0 min-h-[1300px] sm:min-h-[1600px] lg:min-h-[1000px] text-black" title="Стоимость участия" refSection={refCost} id='cost'>
        <EventCost />
      </Section>
      <img src={arcBottom} alt="" className="w-full rotate-180 relative top-[-2px]" />
      <Section title='Частые вопросы' styled="mb-[144px] mt-0" refSection={refFAQ} id='FAQ'>
        <Faq />
      </Section>
      <img src={arcFooter} alt="" className="w-full max-h-80 relative top-[2px]" />
    </main>
    <footer >
      <Section styled="bg-white text-black mt-0 min-h-[380px]" refSection={refContacts} id='contacts'>
        <Footer refs={arrRefs.filter((el)=> el.id !== 4)} />
      </Section>
    </footer>
  </div>
  )
}
