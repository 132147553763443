import { Button } from 'components/baseComponents'

export const ErrorPage = () => {
    const reload = () => {
        window.location.reload()
    }
  return (
    <div className='bg-black h-[100vh] w-[100vw] p-11 text-white flex flex-col justify-center items-center'>
        Произошла непредвиденная ошибка, обновите страницу или сообщите в поддержку!
         
        <Button onClick={reload}>Перезагрузить страницу</Button>
    </div>
  )
}
